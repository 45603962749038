@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
    @apply bg-green-500 text-white text-sm px-3 py-2 rounded hover:bg-green-600;
}

.white-box {
    @apply bg-white rounded-md shadow-md;
}

label {
    @apply text-xs text-gray-600 font-light;
}

input[type=text], input[type=email], input[type=password] {
    @apply block border mb-5 w-full rounded px-3 py-2 font-light outline-none;
}